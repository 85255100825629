// Importing required files/variables
@import "../../index.scss";

.Mint {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 3rem;

    .wallet-container {
        z-index: 100;

        height: $header_height;

        position: fixed;
        top: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1130px) {
            z-index: 0;
            width: 50%;
            position: inherit;
        }

        .wallet {
            padding: 0.625rem 1.25rem;

            margin-right: 4.375rem;

            border-radius: 1.25rem;

            @media (max-width: 1130px) {
                width: 100%;
                padding: 1.75rem 3rem;
                margin-right: 0;
                border-radius: 3.125rem;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 0.938rem;
                line-height: 0.813rem;

                letter-spacing: 0.188rem;
                text-transform: uppercase;

                color: #ffffff;

                @media (max-width: 1130px) {
                    font-size: 1.75rem;

                    letter-spacing: 0.5rem;
                }
            }
        }

        .wallet:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }

    .container {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-top: calc($header_height + 3rem);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: linear-gradient(
            223.75deg,
            #191919 -26.31%,
            rgba(50, 50, 50, 0) 125.37%
        );
        border-radius: 6.25rem;

        img {
            width: 56.25rem;

            @media (max-width: 1260px) {
                width: 40rem;
            }

            @media (max-width: 1100px) {
                width: 30rem;
            }
            margin-top: 3rem;
            margin-bottom: 3rem;
        }

        .main-text {
            #title {
                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 2.5rem;
                line-height: 2.813rem;

                text-align: center;
                letter-spacing: 0.25rem;
                text-transform: uppercase;

                color: #ffffff;
            }

            #subtitle {
                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 1.625rem;
                line-height: 2rem;

                text-align: center;
                letter-spacing: 0.25rem;
                text-transform: uppercase;

                color: #ffffff;
            }
        }

        .images {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-evenly;

            img {
                width: 10rem;

                @media (max-width: 1130px) {
                    width: 8rem;
                }
            }
        }

        .btn-container {
            position: relative;
            top: 2.188rem;

            margin-top: 1rem;

            @media (max-width: 1130px) {
                width: 50%;
                position: inherit;
                margin-bottom: 4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3rem;
            }

            padding: 0.125rem;

            background: linear-gradient(
                180deg,
                #ffffff 8.33%,
                rgba(255, 255, 255, 0) 100%
            );
            border-radius: 50px;

            .btn {
                padding: 1.75rem 3rem;

                background: #00b111;
                border-radius: 3.125rem;

                @media (max-width: 1130px) {
                    width: 100%;
                    position: inherit;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                p {
                    font-family: "Futura", "Josefin Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.75rem;
                    line-height: 0.813rem;

                    letter-spacing: 0.5rem;
                    text-transform: uppercase;

                    color: #ffffff;
                }
            }
        }

        .btn-container:hover {
            cursor: pointer;

            padding: 0.125rem;

            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 4.17%,
                #ffffff 100%
            );
            border-radius: 50px;

            .btn {
                background: #000000;

                p {
                    color: #00ff29;
                }
            }
        }
    }

    .container-minted {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-top: calc($header_height + 3rem);
        margin-bottom: 5rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: linear-gradient(
            223.75deg,
            #191919 -26.31%,
            rgba(50, 50, 50, 0) 125.37%
        );
        border-radius: 6.25rem;

        #logo {
            width: 21rem;

            margin-top: 6.125rem;
            margin-bottom: 4.5rem;
        }

        .main-text {
            #title {
                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 2.5rem;
                line-height: 2.813rem;

                text-align: center;
                letter-spacing: 0.25rem;
                text-transform: uppercase;

                color: #ffffff;
            }

            #subtitle {
                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 1.625rem;
                line-height: 2rem;

                text-align: center;
                letter-spacing: 0.25rem;
                text-transform: uppercase;

                color: #ffffff;
            }
        }

        #nft {
            width: 9.75rem;
            height: 9.75rem;

            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
        }

        .question {
            margin-bottom: 5.625rem;
        }

        #subtitle {
            font-family: "Futura", "Josefin Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 1.625rem;
            line-height: 2rem;

            text-align: center;
            letter-spacing: 0.25rem;
            text-transform: uppercase;

            color: #ffffff;
        }
    }
}
