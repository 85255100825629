// Importing required files/variables
@import "../../index.scss";

.Elements {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 3rem;

    #header {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-top: calc($header_height + 3rem);

        margin-bottom: 2.125rem;

        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.25rem;
        text-transform: uppercase;

        color: #ffffff;
    }

    .container {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 42.69%,
            rgba(33, 33, 33, 0.4) 100%
        );
        border-radius: 6.25rem;

        .elements {
            width: calc(100% - 2 * 7.5rem);

            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-top: 4rem;

            @media (max-width: 860px) {
                width: calc(100% - 2 * 6rem);
            }

            @media (max-width: 780px) {
                width: calc(100% - 2 * 3rem);
            }
            .first {
                width: calc(100% / 2);

                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: row;

                @media (max-width: 1240px) {
                    flex-direction: column;

                    #first-child {
                        margin-bottom: 2rem;
                    }
                }
            }

            .second {
                width: calc(100% / 2);

                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: row;

                @media (max-width: 1240px) {
                    flex-direction: column;

                    #first-child {
                        margin-bottom: 2rem;
                    }
                }
            }

            .element {
                @media (max-width: 1240px) {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: center;
                }

                img {
                    height: 13.75rem;
                    width: auto;

                    @media (max-width: 1480px) {
                        height: 10rem;
                    }

                    margin-bottom: 3.125rem;

                    border: 0.625rem solid #ffffff;
                    border-radius: 0.625rem;
                }

                #name {
                    width: 13.75rem;

                    font-family: "Platonica", "Futura", "Josefin Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 2.5rem;
                    line-height: 2.375rem;

                    letter-spacing: 0.375rem;

                    color: #ffffff;
                }

                #description {
                    width: 13.75rem;

                    font-family: "Arial", "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 2rem;

                    color: #ffffff;

                    @media (max-width: 1130px) {
                        font-family: "Arial", "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 2rem;
                        line-height: 2.813rem;

                        color: #ffffff;
                    }
                }
            }
        }

        #elements-description {
            width: calc(100% - 2 * 7.5rem);

            margin-top: 2.25rem;
            margin-bottom: 4rem;

            font-family: "Arial", "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 2rem;

            color: #ffffff;

            @media (max-width: 1130px) {
                font-family: "Arial", "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 2rem;
                line-height: 2.813rem;

                color: #ffffff;
            }
        }

        .elements-descriptions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .element-description {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                #element-header {
                    width: calc(
                        100% - ($global_left_margin + $global_right_margin)
                    );

                    font-family: "Futura", "Josefin Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.625rem;
                    line-height: 2rem;

                    letter-spacing: 0.25rem;
                    text-transform: uppercase;

                    color: #ffffff;

                    @media (max-width: 1130px) {
                        font-size: 2rem;
                    }
                }

                #element-text {
                    margin-left: 7.5rem;
                    margin-right: 8.75rem;
                    margin-bottom: 2.5rem;
                    margin-top: 1.2rem;

                    font-family: "Arial", "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 2rem;

                    color: #ffffff;

                    @media (max-width: 1130px) {
                        font-family: "Arial", "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 2rem;
                        line-height: 2.813rem;

                        color: #ffffff;
                    }
                }
            }

            .element-description:last-child {
                #element-text {
                    margin-bottom: 4rem;
                }
            }
        }

        #btn {
            width: fit-content;

            padding: 0.625rem 1.25rem;

            background: #2e2e2e;
            border-radius: 20px;

            margin-bottom: 2.5rem;

            font-family: "Futura", "Josefin Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 0.938rem;
            line-height: 0.813rem;

            letter-spacing: 0.188rem;
            text-transform: uppercase;
            text-align: center;

            color: #c9c9c9;

            @media (max-width: 1130px) {
                padding: 1.6rem 2rem;
                font-size: 1.6rem;
                line-height: 1.8rem;
                border-radius: 4rem;
            }
        }

        #btn:hover {
            cursor: pointer;
            color: #00ff29;
        }
    }
}
