// Importing required files/variables
@import "../../index.scss";

.Explanation {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 3rem;

    #header {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-top: calc($header_height + 3rem);

        margin-bottom: 2.125rem;

        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.25rem;
        text-transform: uppercase;

        color: #ffffff;
    }

    .container {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        background: linear-gradient(180deg, #000000 42.69%, #212121 100%);
        border-radius: 100px;

        p {
            margin-top: 4rem;
            margin-left: 6.875rem;
            margin-right: 8.75rem;
            margin-bottom: 4rem;

            font-family: "Arial", "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.813rem;

            color: #ffffff;
        }
    }
}
