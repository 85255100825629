// Importing required files/variables
@import "../../index.scss";

.Footer {
    margin-top: 3.5rem;

    background: #000000;

    .container {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        #link {
            color: #ffffff;
        }

        #link:hover {
            cursor: pointer;
            opacity: 0.6;
        }

        #twitter:hover {
            cursor: pointer;
            opacity: 0.6;
        }

        #twitter {
            margin-bottom: 1.25rem;
        }

        #trade-mark {
            margin-bottom: 1.25rem;

            font-family: "Arial", "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 2rem;

            color: #ffffff;

            @media (max-width: 820px) {
                width: calc(100% - 10rem);
                text-align: center;
            }
        }

        .links {
            #link {
                margin-left: 1.25rem;
                margin-right: 1.25rem;

                font-family: "Arial";
                font-style: normal;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 2rem;

                color: #ffffff;
            }

            #link:hover {
                cursor: pointer;
                opacity: 0.6;
            }
        }
    }
}
