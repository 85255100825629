// Importing required files/variables
@import "../../index.scss";

.Terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(191.6deg, #121212 -68.81%, #151515 194.07%);

    #header {
        width: 100%;

        margin-top: 4rem;
        margin-bottom: 2.875rem;

        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.25rem;
        text-transform: uppercase;

        color: #ffffff;
    }

    .main-heading {
        width: calc(100% - 2 * $global_left_margin) !important;
    }

    #content {
        font-family: "Arial", "Roboto";
        font-style: normal;
        font-weight: 200;
        font-size: 1.5rem;
        line-height: 2rem;

        color: #ffffff;

        @media (max-width: 1130px) {
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }

    #link {
        text-decoration: underline;
        color: #ffffff;
    }

    #link:hover {
        cursor: pointer;
        opacity: 0.6;
    }

    .container {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        padding-top: 4rem;
        margin-bottom: 4.375rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 42.69%,
            rgba(33, 33, 33, 0.4) 100%
        );
        border-radius: 6.25rem;

        .context {
            width: calc(100% - 2 * 6.875rem);
        }

        a {
            text-decoration: none;
        }

        .btn {
            margin-top: 2.5rem;

            padding: 1.2rem 2.5rem;

            background: #2e2e2e;
            border-radius: 3.125rem;

            #link_2 {
                text-decoration: none;

                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 1.75rem;
                line-height: 1.813rem;

                letter-spacing: 0.5rem;
                text-transform: uppercase;
                text-align: center;

                color: #ffffff;
            }
        }

        .btn:hover {
            cursor: pointer;

            #link_2 {
                color: #00ff29;
            }
        }
    }

    #heading {
        font-family: "Arial", "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2rem;

        color: #ffffff;

        @media (max-width: 1130px) {
            font-size: 2.5rem;
            line-height: 2.7rem;
        }
    }

    li {
        color: #ffffff;
    }
}
