.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: fit-content;

    background: linear-gradient(191.82deg, #000000 -0.09%, #151515 30.69%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .Toastify {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 120;
    }
}
