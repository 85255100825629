// Importing required files/variables
@import "../../index.scss";

.Team {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 3rem;

    #header {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-top: calc($header_height + 3rem);

        margin-bottom: 2.125rem;

        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.25rem;
        text-transform: uppercase;

        color: #ffffff;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;

        width: calc(100% - ($global_left_margin + $global_right_margin));

        background: linear-gradient(180deg, #000000 42.69%, #212121 100%);
        border-radius: 6.25rem;

        .members {
            margin-top: 2rem;
            margin-bottom: 2rem;

            width: calc(100% - 2 * 6.875rem);

            .member {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                margin-bottom: 4.75rem;

                @media (max-width: 1000px) {
                    flex-direction: column;
                    margin-bottom: 4rem;
                }

                #name {
                    font-family: "Futura", "Josefin Sans";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2rem;
                    line-height: 1.5rem;

                    letter-spacing: 0.188rem;
                    text-transform: uppercase;

                    color: #ffffff;

                    margin-bottom: 1.125rem;
                }

                #about {
                    margin-right: 7.125rem;

                    font-family: "Arial", "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 2rem;

                    color: #ffffff;

                    #link {
                        color: #ffffff;

                        text-decoration: underline;
                    }

                    #link:hover {
                        cursor: pointer;
                        opacity: 0.6;
                    }

                    @media (max-width: 1130px) {
                        font-family: "Arial", "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 2rem;
                        line-height: 2.813rem;

                        text-align: left;

                        color: #ffffff;
                    }

                    @media (max-width: 1000px) {
                        margin-right: 0;
                        text-align: left;
                    }
                }

                img {
                    width: 9.375rem;
                    height: auto;

                    border: solid #ffffff 0.313rem;
                    border-radius: 6.25rem;

                    @media (max-width: 1000px) {
                        margin-bottom: 1.875rem;
                        width: 12rem;
                    }
                }
            }

            .member:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
