// Importing required files/variables
@import "../../index.scss";

.Wallet {
    #link {
        color: #ffffff;
    }

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(191.82deg, #000000 -0.09%, #151515 30.69%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    #header {
        width: calc(100% - 2 * $global_left_margin);

        margin-top: 4rem;
        margin-bottom: 2.875rem;

        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.25rem;
        text-transform: uppercase;

        color: #ffffff;
    }

    .container {
        .central {
            width: calc(100% - 2 * 6.875rem);
        }

        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-bottom: 4rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 42.69%,
            rgba(33, 33, 33, 0.4) 100%
        );
        border-radius: 6.25rem;

        img {
            width: calc(100% - 2 * 6.875rem);

            margin-top: 4rem;
            margin-bottom: 3.125rem;
        }

        .step_1 {
            ul {
                li {
                    color: #ffffff;

                    p {
                        a {
                            text-decoration: underline;
                        }

                        a:hover {
                            cursor: pointer;
                            opacity: 0.6;
                        }
                    }
                }
            }
        }

        .step_3 {
            margin-bottom: 3rem;

            ul {
                li {
                    color: #ffffff;

                    p {
                        a {
                            text-decoration: underline;
                        }

                        a:hover {
                            cursor: pointer;
                            opacity: 0.6;
                        }
                    }
                }
            }
        }

        .btn {
            padding: 1.2rem 2.5rem;

            background: #2e2e2e;
            border-radius: 3.125rem;

            #link {
                text-decoration: none;

                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 1.75rem;
                line-height: 1.813rem;

                letter-spacing: 0.5rem;
                text-transform: uppercase;
                text-align: center;

                color: #ffffff;
            }
        }

        .btn:hover {
            cursor: pointer;

            #link {
                color: #00ff29;
            }
        }
    }

    #heading {
        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.5rem;
        text-transform: uppercase;

        color: #ffffff;

        @media (max-width: 1130px) {
            font-size: 2.3rem;
            margin-bottom: 1.2rem;
        }
    }

    #content {
        font-family: "Arial", "Roboto";
        font-style: normal;
        font-weight: 200;
        font-size: 1.5rem;
        line-height: 2rem;

        color: #ffffff;

        a {
            text-decoration: underline;
        }

        a:hover {
            cursor: pointer;
            opacity: 0.6;
        }

        @media (max-width: 1130px) {
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }
}
