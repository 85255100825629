// Importing required files/variables
@import "../../index.scss";

.NFTs {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    margin-bottom: 3rem;

    #header {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-top: calc($header_height + 3rem);

        margin-bottom: 1.75rem;

        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.25rem;
        text-transform: uppercase;

        color: #ffffff;
    }

    .container {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 42.69%,
            rgba(33, 33, 33, 0.4) 100%
        );
        border-radius: 100px;

        .nfts {
            width: 100%;

            margin-top: 5rem;
            margin-bottom: 2.5rem;

            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .nft {
                img {
                    width: 16rem;

                    @media (max-width: 1260px) {
                        width: 12rem;
                    }

                    @media (max-width: 1040px) {
                        width: 10rem;
                    }

                    @media (max-width: 930px) {
                        width: 8rem;
                    }

                    height: auto;
                }
            }
        }

        #description {
            margin-top: 2rem;
            margin-bottom: 4rem;
            margin-left: 6.875rem;
            margin-right: 6.875rem;

            font-family: "Arial", "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.813rem;

            text-align: justify;

            color: #ffffff;
        }
    }
}
