// Importing required files/variables
@import "../../index.scss";

.Header {
    z-index: 100;

    width: 100%;
    height: $header_height;

    @media (max-width: 1130px) {
        height: 5rem;
    }

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: left;

    @media (max-width: 1120px) {
        justify-content: center;
    }

    background: #121212;

    a {
        text-decoration: none;
    }

    .web-container {
        margin-left: $global_left_margin;

        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .btn {
            margin-right: 1.5rem;

            padding: 0.625rem 1.25rem;

            @media (max-width: 1400px) {
                padding: 0.625rem 1.1rem;
            }

            background: #2e2e2e;
            border-radius: 1.25rem;

            p {
                font-family: "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 0.938rem;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;
                }

                letter-spacing: 0.188rem;
                text-transform: uppercase;

                color: #b6b4b4;
            }
        }

        .btn:hover {
            cursor: pointer;
            p {
                color: #00ff29;
            }
        }

        .btn:last-child {
            margin-right: 0;
        }
    }

    .mobile-container {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        .header {
            position: absolute;
            width: calc(100% - 2 * 0.938rem);
            height: 5rem;

            display: flex;
            justify-content: space-between;
            align-items: center;

            #menu {
                z-index: 200;
                width: 5rem;

                margin-top: 0.2rem;
                margin-bottom: 0.2rem;
            }

            #menu:hover {
                cursor: pointer;
                opacity: 0.6;
            }

            #logo {
                position: absolute;
                z-index: 100;
                width: 100%;
                text-align: center;

                font-family: "Platonica", "Futura", "Josefin Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 2.8rem;
                line-height: 2.375rem;

                letter-spacing: 0.375rem;

                color: #8d8c8c;
            }
        }

        .options {
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            width: calc(100% - 2 * 0.938rem);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            margin-top: 5rem;
            padding-top: 1.5rem;
            padding-bottom: 2.25rem;

            background-color: rgb(0, 0, 0);

            border-radius: 0px 0px 1.875rem 1.875rem;

            .devider {
                width: 100%;

                background-color: #b6b4b4;
            }
            .option {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 100%;

                margin-bottom: 1.25rem;

                p {
                    width: 100%;

                    margin-bottom: 1rem;

                    text-align: left;
                    text-indent: 5.25rem;
                    text-decoration: none;

                    font-family: "Futura", "Josefin Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.25rem;

                    letter-spacing: 0.188rem;
                    text-transform: uppercase;

                    color: #ffffff;
                }

                #link {
                    width: 100%;

                    text-align: left;
                    text-indent: 5.25rem;
                    text-decoration: none;

                    font-family: "Futura", "Josefin Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.25rem;

                    letter-spacing: 0.188rem;
                    text-transform: uppercase;

                    color: #ffffff;
                }
            }
            .option:last-child {
                margin-bottom: 0;
            }
            .option:hover {
                cursor: pointer;
                opacity: 0.6;
            }
        }

        .horizontal {
            .option {
                p {
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.1rem;
                }

                #link {
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                }
            }
        }
    }
}
