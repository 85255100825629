// Defining 'Arial' font
@font-face {
    font-family: "Arial";
    src: url("../public/fonts/Arial.ttf") format("truetype");
}

// Defining 'Futura Medium' font
@font-face {
    font-family: "Arial";
    src: url("../public/fonts/Futura\ Medium.otf") format("opentype");
}

// Defining 'Platonica' font
@font-face {
    font-family: "Platonica";
    src: url("../public/fonts/Platonica.otf") format("opentype");
}

// Importing fallback fonts
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Defining global values
$global_left_margin: 4.375rem;
$global_right_margin: 4.375rem;

$header_height: 5rem;
$footer_height: 6rem;

$question_height: 3.438rem;

html {
    background-color: linear-gradient(
        191.82deg,
        #000000 -0.09%,
        #151515 30.69%
    );

    font-size: 16px;

    p {
        margin: 0;
    }
}

body {
    background-color: linear-gradient(
        191.82deg,
        #000000 -0.09%,
        #151515 30.69%
    );
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
