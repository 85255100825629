// Importing required files/variables
@import "../../index.scss";

.FAQs {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 3rem;

    #header {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        margin-top: calc($header_height + 3rem);

        margin-bottom: 2.125rem;

        font-family: "Futura", "Josefin Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 2.5rem;
        line-height: 2.813rem;

        letter-spacing: 0.25rem;
        text-transform: uppercase;

        color: #ffffff;
    }

    .container {
        width: calc(100% - ($global_left_margin + $global_right_margin));

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 42.69%,
            rgba(33, 33, 33, 0.4) 100%
        );
        border-radius: 6.25rem;

        .questions {
            width: calc(100% - 2 * 6.875rem);

            @media (max-width: 1130px) {
                width: calc(100% - 8.75rem);
            }

            margin-top: 3rem;
            margin-bottom: 3rem;

            .question {
                margin-bottom: 0.75rem;

                background: linear-gradient(
                    90deg,
                    #fff14a 0%,
                    #fff14a 73.42%,
                    #fff14a 93.97%
                );
                border-radius: 0px 0px 50px 0px;

                .header {
                    width: 100%s;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    #question-text {
                        margin-left: 1.25rem;
                        margin-top: 0.6rem;
                        margin-bottom: 0.6rem;

                        font-family: "Futura", "Josefin Sans";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 1.75rem;
                        line-height: 2.813rem;

                        letter-spacing: 0.313rem;
                        text-transform: uppercase;

                        color: #000000;
                    }

                    .btn {
                        margin-right: 2.25rem;

                        #up {
                            transform: rotate(180deg);
                        }
                    }

                    .btn:hover {
                        cursor: pointer;
                        opacity: 0.6;
                    }
                }

                .header:hover {
                    cursor: pointer;

                    .btn {
                        opacity: 0.6;
                    }
                }

                #answer {
                    padding-bottom: 2rem;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .divider {
                        width: calc(100% - 2 * 1.25rem);

                        margin: 0.75rem 0rem;

                        border: 1px solid #b6b4b4;
                    }

                    #text {
                        margin-left: 1.25rem;
                        margin-right: 5rem;

                        font-family: "Arial", "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.5rem;
                        line-height: 2rem;

                        color: #000000;

                        #link {
                            color: #000000;
                            text-overflow: clip;
                            text-decoration: underline;
                        }

                        #link:hover {
                            cursor: pointer;
                            opacity: 0.6;
                        }

                        @media (max-width: 1130px) {
                            font-size: 2rem;
                            line-height: 2.4rem;
                        }
                    }
                }
            }

            .question:last-child {
                margin-bottom: 0;
            }
        }
    }
}
